import React, { useRef, useEffect } from 'react';
import styles from './PrivacyPolicyPage.module.scss';

const PrivacyPolicyPage = () => {

  

  return (
    <section id="legal" className={styles.legalSection}>
      <div className={styles.legalContainer}>
        <h2 className={styles.title}>Politique de confidentialité</h2>

        <div className={styles.article}>
            <h3>1. Collecte des données personnelles</h3>
            <p>Notre société collecte les données personnelles suivantes :</p>
            <ul>
                <li>Nom et prénom</li>
                <li>Adresse email</li>
            </ul>
            <p>Ces informations sont collectées uniquement via notre formulaire de contact pour répondre à vos demandes d'information.</p>
        </div>

        <div className={styles.article}>
            <h3>2. Utilisation des données</h3>
            <p>Les données collectées sont utilisées pour :</p>
            <ul>
                <li>Répondre à vos demandes d'information</li>
                <li>Vous envoyer notre newsletter (sous réserve de votre consentement explicite)</li>
                <li>Gérer vos demandes de contact</li>
            </ul>
        </div>

        <div className={styles.article}>
            <h3>3. Base légale du traitement</h3>
            <p>Le traitement de vos données personnelles est basé sur :</p>
            <ul>
                <li>Votre consentement pour l'inscription à la newsletter</li>
                <li>Notre intérêt légitime pour le traitement des demandes de contact</li>
                <li>L'exécution de mesures précontractuelles prises à votre demande</li>
            </ul>
        </div>

        <div className={styles.article}>
            <h3>4. Durée de conservation</h3>
            <p>Nous conservons vos données pendant :</p>
            <ul>
                <li>3 ans à compter de votre dernier contact pour les données liées aux demandes d'information</li>
                <li>Jusqu'à votre désabonnement pour les données liées à la newsletter</li>
            </ul>
        </div>

        <div className={styles.article}>
            <h3>5. Cookies</h3>
            <p>Notre site utilise uniquement des cookies techniques essentiels au fonctionnement du site, notamment pour :</p>
            <ul>
                <li>La gestion de votre session d'authentification</li>
                <li>Le maintien de vos préférences de navigation</li>
            </ul>
            <p>Ces cookies sont strictement nécessaires au fonctionnement du site et ne nécessitent pas votre consentement préalable conformément à l'article 82 de la loi Informatique et Libertés.</p>
        </div>

        <div className={styles.article}>
            <h3>6. Vos droits</h3>
            <p>Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez des droits suivants :</p>
            <ul>
                <li>Droit d'accès à vos données</li>
                <li>Droit de rectification</li>
                <li>Droit d'effacement</li>
                <li>Droit à la limitation du traitement</li>
                <li>Droit d'opposition</li>
                <li>Droit à la portabilité de vos données</li>
                <li>Droit de retirer votre consentement à tout moment</li>
            </ul>
            <p>Pour exercer ces droits, vous pouvez nous contacter par email à : dpo@scriptales.com</p>
        </div>

        <div className={styles.article}>
            <h3>7. Sécurité</h3>
            <p>Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos données personnelles contre tout accès, modification, divulgation ou destruction non autorisés.</p>
        </div>

        <div className={styles.article}>
            <h3>8. Modification de la politique de confidentialité</h3>
            <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications prendront effet dès leur publication sur le site.</p>
        </div>

        <div className={styles.article}>
            <h3>9. Contact</h3>
            <p>Pour toute question concernant cette politique de confidentialité ou pour exercer vos droits, vous pouvez nous contacter à :<br />
            dpo@scriptales.com</p>
        </div>
        
        <footer>
            <p>Dernière mise à jour : 07 février 2025</p>
        </footer>
      </div>
    </section>
  );
};

export default PrivacyPolicyPage;