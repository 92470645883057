import React from "react";
import { Link } from 'react-router-dom';
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Colonne 1 : Menu de navigation */}
        <div className="footer-column">
          <ul className="footer-menu">
            <li><Link to="/">Accueil</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
          <ul className="footer-legal"><span className="text-bold">Apps</span>
            <li><Link to="/seo-generator">Générateur de contenu SEO</Link></li>
          </ul>
        </div>

        {/* Colonne 2 : Pages supplémentaires */}
        <div className="footer-column">
          <ul className="footer-legal">
            <li><Link to="/mentions-legales">Mentions légales</Link></li>
            <li><a href="#cgv">CGV</a></li>
            <li><Link to="/politique-confidentialite">Politique de confidentialité</Link></li>
          </ul>
        </div>

        {/* Colonne 3 : Logo et réseaux sociaux */}
        <div className="footer-column">
          <div className="footer-logo">
            <img src="test4.png" alt="Logo" className="logo" />
          </div>
          <div className="footer-social">
            {/* <a href="https://facebook.com/100087577732958/" target="_blank" rel="noopener noreferrer" aria-label="Facebook"> */}
            <a href="https://facebook.com/61556543451327/" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <i className="fab fa-facebook"></i>
            </a>
            {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <i className="fab fa-twitter"></i>
            </a> */}
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Scriptales. Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default Footer;
