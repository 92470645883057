import React, { useRef, useEffect } from 'react';
import styles from './LegalNoticePage.module.scss';

const LegalNoticePage = () => {

  

  return (
    <section id="legal" className={styles.legalSection}>
        <div className={styles.legalContainer}>
            <h2 className={styles.title}>Mentions légales</h2>

            <div className={styles.article}>
                <h3>Éditeur du site</h3>
                <p>
                    SCRIPTALES<br />
                    Société par Actions Simplifiée Unipersonnelle (SASU)<br />
                    Capital social : 1 000 euros<br />
                    Siège social : 173, Avenue de Saignon 84400 APT<br />
                    SIREN : 939 871 141<br />
                    RCS AVIGNON : 939871141 / RM : 939871141 84<br />
                    Directeur de la publication : Nathaëlle LAMBERT<br />
                    Email : contact@scriptales.com<br />
                </p>
            </div>

            <div className={styles.article}>
                <h3>Hébergeur</h3>
                <p>OVH RCS Roubaix – Tourcoing 424 761 419 00045 Code APE 6202A<br />
                N° TVA : FR 22 424 761 419<br />
                Siège social : 2 rue Kellermann - 59100 Roubaix - France</p>
            </div>
        </div>
    </section>
  );
};

export default LegalNoticePage;